import React from 'react'

const className = 'font-sansSerif text-p3 antialiased md:text-p4md'
export const P4ClassName = className

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > {
  as?: 'button' | 'a' | 'div' | 'span'
  small?: boolean
}

const P4: React.FC<Props> = ({ children, className, ...other }) => {
  return (
    <p
      className={P4ClassName + (className ? ` ${className}` : '')}
      {...other}
    >
      {children}
    </p>
  )
}

export default P4
